var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(" Registration complete ")
      ]),
      _c("gov-body", [
        _vm._v(" Thank you for registering on " + _vm._s(_vm.appName) + ". ")
      ]),
      _c("gov-body", [
        _vm._v(
          "\n    Your details will be verified by our team within the next 5 working days.\n  "
        )
      ]),
      _c("gov-body", [_vm._v("Once your account approved, you will be able:")]),
      _c("gov-list", { attrs: { bullet: true } }, [
        _c("li", [_vm._v("to access and edit your organisation page")]),
        _c("li", [_vm._v("create new service pages")]),
        _c("li", [_vm._v("edit existing service pages")])
      ]),
      _c(
        "gov-body",
        [
          _vm._v(
            "\n    If you haven't heard back from us within 5 working days please email the\n    team at\n    "
          ),
          _c("gov-link", { attrs: { href: "mailto:" + _vm.contactEmail } }, [
            _vm._v(_vm._s(_vm.contactEmail))
          ])
        ],
        1
      ),
      _c("gov-button", { attrs: { to: { name: "dashboard" }, start: "" } }, [
        _vm._v("Home")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }